
// Общие константы без категории

export const DOMAIN_NAME = window.location.host;

export enum AuthorizationStatus {
  Auth = 'AUTH',
  NoAuth = 'NO_AUTH',
  Unknown = 'UNKNOWN',
}

export enum NameSpace {
  App = 'APP',
  Auth = 'AUTH',
  User = 'USER',
  Casino = 'CASINO',
}

export const topCountriesId = [
  '225', // Мир
  '223', // Европа
  '231', // Англия
  '198', // Франция
  '79', // Италия
  '78', // Испания
  '53', // Германия
  '1', // Россия
  '2', // Украина
];
