import {
  ISportItem,
  TEventDetailed,
  ICountryItem,
  ITournamentItem,
  TTournamentEvents,
  IEventItem,
  TEventBetItem,
  TEventStatItem,
} from './line';
import { getSportIconName } from '@shared/utils/helpers/common';
import {
  ICountryServer,
  EventDetailedServer,
  EventShortServer,
  ISportServer,
  TournamentEventsServer,
  ITournamentServer,
  DataType,
  EventOcItemServer,
} from './types';

const getIconLink = (iconName: string) => {
  if (iconName) {
    const name = iconName.includes('.png') ? iconName : `${iconName}.png`;
    return `https://cdn.incub.space/v1/opp/icon/${name}`;
  }
  return '';
};


const selectMainBets = (groups: EventOcItemServer[]): EventOcItemServer[] => {
  let filteredGroups = groups.filter((g) => g.oc_group_name.toLowerCase() === '1x2');

  if (filteredGroups.length <= 0) {
    filteredGroups = groups.slice(0, 1);
  }
  return filteredGroups;
};

export const adapteSport = (sports: ISportServer[], dataType: DataType): ISportItem[] =>
  sports.map((item) => ({
    id: `${item.id}`,
    name: item.name,
    nameRu: item.name_ru,
    nameEn: item.name_en,
    counter: item.counter,
    icon: getSportIconName(`${item.id}`),
    dataType,
  }));

export const adapteCountry = (countries: ICountryServer[], dataType: DataType): ICountryItem[] =>
  countries.map((item) => ({
    id: `${item.id}`,
    name: item.name,
    sportId: `${item.sport_id}`,
    counter: item.counter,
    dataType,
  }));

export const adapteTournament = (tournaments: ITournamentServer[], dataType: DataType): ITournamentItem[] =>
  tournaments.map((item) => ({
    id: `${item.id}`,
    name: item.name,
    nameRu: item.name_ru,
    nameEn: item.name_en,
    sportId: `${item.sport_id}`,
    counter: item.counter,
    countryId: `${item.country_id}`,
    dataType
  }));


const adapteEventBet = (bet: EventOcItemServer): TEventBetItem => ({
  id: bet.oc_pointer,
  name: bet.oc_name,
  groupName: bet.oc_group_name,
  rate: bet.oc_rate,
  blocked: bet.oc_block,
  isActive: false,
});

export const adapreDetailedEvent = (event: EventDetailedServer, dataType: DataType): TEventDetailed => ({
  dataType,
  id: `${event.game_id}`,
  mainId: `${event.game_mid}`,
  num: `${event.game_num}`,
  startDate: new Date(event.game_start * 1000).toISOString(),
  gameDesk: event.game_desk,
  gameDopName: event.game_dop_name,
  periodName: event.period_name,
  scoreTotal: event.score_full.split(':').join(' : '),
  scorePeriod: event.score_period.split(';').map((item) => item.split(':').join(' : ')).join(' / '),
  scoreExtra: event.score_extra,
  team1: {
    id: String(event.opp_1_id),
    name: event.opp_1_name,
    icon: getIconLink(event.opp_1_icon),
  },
  team2: {
    id: String(event.opp_1_id),
    name: event.opp_2_name,
    icon: getIconLink(event.opp_2_icon),
  },
  sport: {
    id: String(event.sport_id),
    name: event.sport_name,
  },
  tournament: {
    id: String(event.tournament_id),
    name: event.tournament_name,
  },
  timer: event.timer,
  finale: event.finale,
  pitch: event.pitch ? `${event.pitch}` : '',
  extraTime: event.extra_time,
  statList: event.stat_list.reduce((acc, stat) => {
    if (stat.name) {
      acc.push({
        name: stat.name ?? '',
        team1: stat.opp1,
        team2: stat.opp2
      });
    }
    return acc;
  }, [] as TEventStatItem[]),

  streamUrl: '',
  isFavorite: false,
  isLive: dataType === 'live',

  betsGroups: event.game_oc_list.map((item) => ({
    id: String(item.group_id),
    name: item.group_name,
    bets: item.oc_list.map(adapteEventBet)
  })),

  subgames: event.sub_games.map((subgame) => ({
    id: String(subgame.game_id),
    name: subgame.game_name,
    num: String(subgame.game_num),
  }))
});

export const adapteEventItem = (event: EventShortServer, dataType: DataType): IEventItem => ({
  dataType,
  id: `${event.game_id}`,
  mainId: `${event.game_mid}`,
  num: `${event.game_num}`,
  startDate: new Date(event.game_start * 1000).toISOString(),
  gameDesk: event.game_desk,
  gameDopName: event.game_dop_name,
  periodName: event.period_name,
  scoreTotal: event.score_full.split(':').join(' : '),
  scorePeriod: event.score_period.split(';').map((item) => item.split(':').join(' : ')).join(' / '),
  scoreExtra: event.score_extra,
  team1: {
    id: String(event.opp_1_id),
    name: event.opp_1_name,
    icon: getIconLink(event.opp_1_icon),
  },
  team2: {
    id: String(event.opp_1_id),
    name: event.opp_2_name,
    icon: getIconLink(event.opp_2_icon),
  },
  sport: {
    id: String(event.sport_id),
    name: event.sport_name,
  },
  tournament: {
    id: String(event.tournament_id),
    name: event.tournament_name,
  },
  timer: event.timer,
  finale: event.finale,
  pitch: event.pitch ? `${event.pitch}` : '',
  extraTime: event.extra_time,
  statList: event.stat_list.reduce((acc, stat) => {
    if (stat.name) {
      acc.push({
        name: stat.name ?? '',
        team1: stat.opp1,
        team2: stat.opp2
      });
    }
    return acc;
  }, [] as TEventStatItem[]),
  streamUrl: '',
  isFavorite: false,
  isLive: dataType === 'live',

  bets: event.game_oc_list.map(adapteEventBet),
  countryId: `${event.country_id}`,
  countryName: event.country_name,
  mainBets: selectMainBets(event.game_oc_list).map(adapteEventBet)
});

export const adapteTournamentsEvents = (res: TournamentEventsServer[], dataType: DataType): TTournamentEvents[] =>
  res.map((item) => ({
    id: `${item.tournament_id}`,
    name: item.tournament_name,
    sportIcon: getSportIconName(`${item.events_list[0].sport_id ?? ''}`),
    events: item.events_list.map((event) => adapteEventItem(event, dataType))
  }));
