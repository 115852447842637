import { baseApi } from '@/shared/store/api';
import {
  adapteInbetGames,
  adapteMascotGames,
  adaptePragmaticGames,
  adapteRplGames,
  adapteSmartsoftGames,
  adapteVegasGames,
  adapteLastwins,
  adapteDigitainGames,
  adapreEndorphinaGames,
  adapteAgtGames,
  adapteFivemenGames,
} from '../model/adapter';
import type {
  AgtStartSessionRes,
  AviatrixSessionRes,
  BetGamesToken,
  BetGamesTokenRes,
  CasinoGame,
  CreateAgtSessionParams,
  CreateAviatrixSessionParams,
  CreateFivemenSessionParams,
  CreateMascotSessionParams,
  CreatePragmaticSession,
  CreateSmartsoftSessionParams,
  CreateVegasSessionParams,
  GetBetgamesTokenParams,
  GetPragmaticLobbyGames,
  GetRplGamesParams,
  InbetSession,
  InbetSessionRes,
  MascotSessionRes,
  PragmaticSessionRes,
  RplSession,
  RplSessionResponse,
  SmartsoftSessionRes,
  TVBetSession,
  TVBetSessionRes,
  VegasSessionResponse,
  StartDigitainSession,
  DigitainSessionResponse,
  Lastwin,
  CreateEndorphinaSessionParams,
  EndorphinaSessionRes,
} from '../model/types';

const casinoApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getInbetGames: build.query<CasinoGame[], void>({
      query: () => ({
        url: '/v1/inbet/games/list',
      }),
      transformResponse: adapteInbetGames,
    }),

    createInbetSession: build.query<InbetSession, void>({
      query: () => ({
        url: '/v1/inbet/session',
        method: 'POST',
      }),
      transformResponse: (res: InbetSessionRes) => ({
        session: res.session,
        currency: res.currency,
        customerId: res.customer_id,
      }),
    }),

    getRplGames: build.query<CasinoGame[], GetRplGamesParams>({
      query: ({ gameType }) => ({
        url: '/v1/rpl/games/list',
        params: {
          'game_type': gameType
        }
      }),
      transformResponse: adapteRplGames,
    }),

    createRplSession: build.query<RplSession, void>({
      query: () => ({
        url: '/v1/rpl/session',
        method: 'POST',
      }),
      transformResponse: (res: RplSessionResponse) => ({
        session: res.session,
        currency: res.currency,
        customerId: res.customer_id,
      }),
    }),

    getVegasGames: build.query<CasinoGame[], void>({
      query: () => 'v1/vegas/games',
      transformResponse: adapteVegasGames,
    }),

    createVegasSession: build.query<string, CreateVegasSessionParams>({
      query: ({ gameId, lang = 'en' }) => ({
        url: 'v1/vegas/session',
        method: 'POST',
        params: {
          'game_id': gameId,
          lang,
        }
      }),
      transformResponse: (res: VegasSessionResponse) => res.data.url,
    }),

    getMascotGames: build.query<CasinoGame[], void>({
      query: () => 'v1/mascot/games',
      transformResponse: adapteMascotGames,
    }),

    createMascotSession: build.query<string, CreateMascotSessionParams>({
      query: ({ gameId, lang = 'en' }) => ({
        url: 'v1/mascot/session',
        method: 'POST',
        params: {
          'game_id': gameId,
          lang,
        }
      }),
      transformResponse: (res: MascotSessionRes) => res.data.url,
    }),

    getSmartsoftGames: build.query<CasinoGame[], void>({
      query: () => 'v1/smartsoft/games',
      transformResponse: adapteSmartsoftGames,
    }),

    createSmartsoftSession: build.query<string, CreateSmartsoftSessionParams>({
      query: ({ gameCategory, gameName, returnUrl, lang }) => ({
        url: 'v1/smartsoft/session',
        method: 'POST',
        body: {
          'game_category': gameCategory,
          'game_name': gameName,
          'return_url': returnUrl,
          'lang': lang
        }
      }),
      transformResponse: (res: SmartsoftSessionRes) => res.data.url,
    }),

    getPragmaticLobbyGames: build.query<CasinoGame[], GetPragmaticLobbyGames>({
      query: (category) => ({
        url: 'v1/pragmatic/lobby_games',
        params: {
          category
        }
      }),
      transformResponse: adaptePragmaticGames,
    }),

    getPragmaticGames: build.query<CasinoGame[], void>({
      query: () => 'v1/pragmatic/games',
      transformResponse: adaptePragmaticGames,
    }),

    createPragmaticSession: build.query<string, CreatePragmaticSession>({
      query: (params) => ({
        url: 'v1/pragmatic/session',
        method: 'POST',
        params: {
          'game_id': params.gameId,
          'lang': params.lang ?? 'en',
          'is_demo': params.isDemo,
          'balance_type': params.balanceType ?? 'real',
          'lobby_url': params.lobbyUrl,
        }
      }),
      transformResponse: (res: PragmaticSessionRes) => res.data.url,
    }),

    getBetgamesToken: build.query<BetGamesToken, GetBetgamesTokenParams>({
      query: (params) => ({
        url: 'v1/betgames/token',
        params: {
          currency: params.currency
        },
      }),
      transformResponse: (res: BetGamesTokenRes) => ({
        id: res.id,
        token: res.token,
        accountId: res.account_id,
        updated: res.updated,
        currency: res.currency,
        partnerCodeBetgames: res.partner_code_betgames,
        partnerCodeTwain: res.partner_code_twain,
      }),
    }),

    createTVBetSession: build.query<TVBetSession, void>({
      query: () => ({
        url: 'v1/tvbet/session',
        method: 'POST',
      }),
      transformResponse: (res: TVBetSessionRes) => ({
        token: res.token,
        clientId: res.client_id,
      })
    }),

    getAgtGames: build.query<CasinoGame[], void>({
      query: () => 'v1/agt/games',
      transformResponse: adapteAgtGames,
    }),

    createAgtSession: build.query<string, CreateAgtSessionParams>({
      query: ({ gameId, closeUrl }) => ({
        url: 'v1/agt/session',
        method: 'POST',
        params: {
          'game_id': gameId,
          'close_url': closeUrl,
        }
      }),
      transformResponse: (res: AgtStartSessionRes) => res.data.data,
    }),

    createAviatrixSession: build.query<string, CreateAviatrixSessionParams>({
      query: (params) => ({
        url: 'v1/aviatrix/session',
        method: 'POST',
        body: {
          'product_id': params.productId,
          'lang': params.lang,
          'lobby_url': params.lobbyUrl,
        }
      }),
      transformResponse: (res: AviatrixSessionRes) => res.data.url,
    }),

    createFivemenSession: build.query<string, CreateFivemenSessionParams>({
      query: (params) => ({
        url: 'v1/fivemen/games/launch',
        method: 'POST',
        body: {
          'lang': params.lang,
          'game_id': params.gameId,
          'platform_type': params.platformType,
          'lobby_url': params.lobbyUrl,
          'deposit_url': params.depositUrl,
        }
      })
    }),

    getDigitainGames: build.query<CasinoGame[], void>({
      query: () => 'v1/digitain/games',
      transformResponse: adapteDigitainGames,
    }),

    createDigitainSession: build.query<string, StartDigitainSession>({
      query: (params) => ({
        url: 'v1/digitain/session',
        method: 'POST',
        body: {
          'game_id': params.gameId,
          'play_mode': params.playMode,
          'device_type': params.deviceType,
          'lang': params.lang,
          'main_domain': params.mainDomain,
          'cashier_url': params.cashierUrl,
        }
      }),
      transformResponse: (res: DigitainSessionResponse) => res.data.url,
    }),

    getEndorphinaGames: build.query<CasinoGame[], void>({
      query: () => 'v1/endorphina/games',
      transformResponse: adapreEndorphinaGames,
    }),

    createEndorphinaSession: build.query<string, CreateEndorphinaSessionParams>({
      query: ({ exitUrl, gameId }) => ({
        url: 'v1/endorphina/session',
        method: 'POST',
        body: {
          'exit_url': exitUrl,
          'game_id': gameId,
        }
      }),
      transformResponse: (res: EndorphinaSessionRes) => res.data.url,
    }),

    getLastwins: build.query<Lastwin[], void>({
      query: () => ({
        url: '/v1/lastwins',
      }),
      transformResponse: adapteLastwins,
    }),

    getFivemenGames: build.query<CasinoGame[], void>({
      query: () => 'v1/fivemen/games/list',
      transformResponse: adapteFivemenGames
    }),

  })
});

export const {
  useGetInbetGamesQuery,
  useGetRplGamesQuery,
  useGetVegasGamesQuery,
  useGetMascotGamesQuery,
  useGetSmartsoftGamesQuery,
  useGetPragmaticGamesQuery,
  useGetPragmaticLobbyGamesQuery,
  useGetBetgamesTokenQuery,
  useGetAgtGamesQuery,
  useGetFivemenGamesQuery,
  useGetDigitainGamesQuery,
  useGetEndorphinaGamesQuery,
  useCreateTVBetSessionQuery,
  useCreateInbetSessionQuery,
  useCreateVegasSessionQuery,
  useCreatePragmaticSessionQuery,
  useCreateMascotSessionQuery,
  useCreateAgtSessionQuery,
  useCreateSmartsoftSessionQuery,
  useCreateAviatrixSessionQuery,
  useCreateFivemenSessionQuery,
  useCreateDigitainSessionQuery,
  useCreateEndorphinaSessionQuery,
  useGetLastwinsQuery,
} = casinoApi;
export default casinoApi;
