import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { useTranslation } from 'react-i18next';
import Drawer from '@/shared/components/drawer/drawer';
import { PartnerStats, PlayerStats } from '@/modules/partner-program/model/types';
import TextField from '@/shared/components/text-field/text-field';
import Descriptions from '@/shared/components/descriptions/descriptions';
import PartnerPlayersList from '../../player/partner-players-list/partner-players-list';
import Mobile from '@/shared/components/responsive/mobile';
import Desktop from '@/shared/components/responsive/desktop';
import PartnerPlayersTable from '../../player/partner-players-table/partner-players-table';
import { PropsWithCN } from '@/shared/types/common';
import './partner-stats-drawer.scss';
import PartnerStatsCards from '../partner-stats-cards/partner-stats-cards';

const b = bemCn('partner-stats-drawer');

type PartnerStatsDrawerProps = PropsWithCN & {
  title?: string;
  isOpen: boolean;
  onClose?: () => void;
  stats?: PartnerStats;
  players?: PlayerStats[];
  metaInfo?: React.ReactNode;
}

const PartnerStatsDrawer = ({
  className,
  title,
  isOpen,
  onClose,
  stats,
  players,
  metaInfo,
}: PartnerStatsDrawerProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });

  return (
    <Drawer
      title={title}
      className={b(null, className)}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={b('wrapper')}>
        {metaInfo && (
          <div className={b('info', b('section'))}>
            {metaInfo}
          </div>
        )}
        <div className={b('filter', b('section'))}>
          <div className={b('filter-fields')}>
            <TextField className={b('filter-field')}
              label={t('filter.date-from', 'Дата (от)')}
              inverted
            />
            <TextField className={b('filter-field')}
              label={t('filter.date-to', 'Дата (до)')}
              inverted
            />
          </div>
        </div>

        {stats && (
          <>
            <Mobile>
              <Descriptions className={b('total-stat', b('section'))} title={t('general-stats', 'Общая статистика')}>
                {Object.entries(stats).map(([label, value]) => (
                  <Descriptions.Item key={label + value} label={label}>
                    {value}
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </Mobile>
            <Desktop>
              <section className={b('players', b('section'))}>
                <h3 className={b('subtitle')}>{t('general-stats', 'Общая статистика')}</h3>
                <PartnerStatsCards items={Object.entries(stats).map(([label, value]) => ({ label, value }))} />
              </section>
            </Desktop>
          </>
        )}

        {players && (
          <section className={b('players', b('section'))}>
            <h3 className={b('subtitle')}>{t('users', 'Пользователи')}</h3>
            {players.length === 0 && (
              <p className={b('no-players')}>{t('no-users', 'Нет пользователей')}</p>
            )}
            <Mobile>
              <PartnerPlayersList players={players} />
            </Mobile>
            <Desktop>
              <PartnerPlayersTable players={players} />
            </Desktop>
          </section>
        )}
      </div>
    </Drawer>
  );
};

export default PartnerStatsDrawer;
