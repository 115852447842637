import { baseApi } from '@/shared/store/api';
import type { GetSubPartnerLinks, GetSubPartnerPlayers, GetSubPartnerPromocodes, GetSubPartners, GetSubPartnerStats, GetManagerStats, GetPartnerLinkPlayers, GetPartnerLinkStats, GetPartnerPromocodePlayers, GetPartnerPromocodeStats, ManagerLinkRes, Subpartners, SubpartnersServer, PartnerLinks, PartnerLinksServer, PartnerPlayers, PartnerPlayersServer, PartnerPromocodes, PartnerPromocodesServer, PartnerStats } from '../model/types';
import { adapteLink, adapteManagerPartner, adaptePartnerStats, adaptePlayerStats, adaptePromocode } from '../lib/adapter';


const managerApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getManagerLink: build.query<string, void>({
      query: () => '/v1/manager/link',
      transformResponse: (res: ManagerLinkRes) => res.link,
    }),

    getSubPartners: build.query<Subpartners, GetSubPartners>({
      query: (params) => ({
        url: '/v1/manager/partners',
        params: {
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      transformResponse: (res: SubpartnersServer) => ({
        count: res.count,
        items: res.items.map(adapteManagerPartner),
      })
    }),

    getSubPartnerLinks: build.query<PartnerLinks, GetSubPartnerLinks>({
      query: ({ partnerId, ...params }) => ({
        url: `/v1/manager/partners/${partnerId}/links`,
        params: {
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      transformResponse: (res: PartnerLinksServer) => ({
        count: res.count,
        items: res.items.map(adapteLink),
      })
    }),

    getSubPartnerLinkStats: build.query<PartnerStats, GetPartnerLinkStats>({
      query: ({ refCode, ...params }) => ({
        url: `/v1/manager/links/${refCode}/stats`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
        }
      }),
      transformResponse: adaptePartnerStats,
    }),

    getSubPartnerLinkPlayers: build.query<PartnerPlayers, GetPartnerLinkPlayers>({
      query: ({ refCode, ...params }) => ({
        url: `/v1/manager/links/${refCode}/players`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      transformResponse: (res: PartnerPlayersServer) => ({
        count: res.count,
        items: res.items.map(adaptePlayerStats),
      })
    }),

    getSubPartnerPromocodes: build.query<PartnerPromocodes, GetSubPartnerPromocodes>({
      query: ({ partnerId, ...params }) => ({
        url: `/v1/manager/partners/${partnerId}/promocodes`,
        params: {
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      transformResponse: (res: PartnerPromocodesServer) => ({
        count: res.count,
        items: res.items.map(adaptePromocode),
      })
    }),

    getSubPartnerPromocodeStats: build.query<PartnerStats, GetPartnerPromocodeStats>({
      query: ({ promocodeId, ...params }) => ({
        url: `/v1/manager/promocodes/${promocodeId}/stats`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
        }
      }),
      transformResponse: adaptePartnerStats,
    }),

    getSubPartnerPromocodePlayers: build.query<PartnerPlayers, GetPartnerPromocodePlayers>({
      query: ({ promocodeId, ...params }) => ({
        url: `/v1/manager/promocodes/${promocodeId}/players`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      transformResponse: (res: PartnerPlayersServer) => ({
        count: res.count,
        items: res.items.map(adaptePlayerStats),
      })
    }),

    getSubPartnerStats: build.query<PartnerStats, GetSubPartnerStats>({
      query: ({ partnerId, ...params }) => ({
        url: `/v1/manager/partners/${partnerId}/stats`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
        }
      }),
      transformResponse: adaptePartnerStats,
    }),

    getSubPartnerPlayers: build.query<PartnerPlayers, GetSubPartnerPlayers>({
      query: ({ partnerId, ...params }) => ({
        url: `/v1/manager/partners/${partnerId}/players`,
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
          'page_size': params.pageSize,
          'page': params.page,
          'ascending': params.ascending,
        }
      }),
      transformResponse: (res: PartnerPlayersServer) => ({
        count: res.count,
        items: res.items.map(adaptePlayerStats),
      })
    }),

    getManagerTotalStats: build.query<PartnerStats, GetManagerStats>({
      query: (params) => ({
        url: '/v1/manager/stats',
        params: {
          'currency': params.currency,
          'created_from': params.createdFrom,
          'created_to': params.createdTo,
        }
      }),
      transformResponse: adaptePartnerStats,
    }),
  })
});

export const {
  useGetManagerLinkQuery,
  useGetSubPartnersQuery,

  useGetSubPartnerLinksQuery,
  useGetSubPartnerLinkStatsQuery,
  useLazyGetSubPartnerLinkPlayersQuery,

  useGetSubPartnerPromocodesQuery,
  useGetSubPartnerPromocodeStatsQuery,
  useGetSubPartnerPromocodePlayersQuery,

  useGetSubPartnerStatsQuery,
  useGetSubPartnerPlayersQuery,

  useGetManagerTotalStatsQuery,
} = managerApi;

export { managerApi };
