import { useTranslation } from 'react-i18next';
import ButtonIcon, { IButtonIconProps } from '@/shared/components/button-icon/button-icon';
import PartnerStatsDrawer from '../../core/partner-stats-drawer/partner-stats-drawer';
import { PartnerPromocode } from '@/modules/partner-program/model/types';
import { useGetPartnerPromocodePlayersQuery, useGetPartnerPromocodeStatsQuery } from '@/modules/partner-program/api/partnerApi';
import { useState } from 'react';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';


type PromocodeStatsBtnProps = IButtonIconProps & {
  promocode: PartnerPromocode;
}

const PromocodeStatsBtn = ({
  className,
  promocode,
}: PromocodeStatsBtnProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const [opened, setOpened] = useState(false);

  const { data: promocodeStatsData } = useGetPartnerPromocodeStatsQuery({
    currency: 'RUB',
    promocodeId: promocode.id
  }, { skip: !opened });

  const { data: promocodePlayers } = useGetPartnerPromocodePlayersQuery({
    currency: 'RUB',
    promocodeId: promocode.id
  }, { skip: !opened });

  return (
    <>
      <ButtonIcon className={className}
        icon='stats'
        variant='bordered'
        onClick={() => setOpened(true)}
      />

      <PartnerStatsDrawer
        title={t('promocode-stats', 'Статистика по промокоду')}
        isOpen={opened}
        onClose={() => setOpened(false)}
        metaInfo={
          <>
            <p>{t('promocode', 'Промокод')}: {promocode.name}</p>
            <p>{t('created', 'Создан')}: {dayjsCustom(promocode.created).format('DD.MM.YYYY')}</p>
          </>
        }
        stats={promocodeStatsData}
        players={promocodePlayers?.items}
      />
    </>
  );
};

export default PromocodeStatsBtn;
