import { useTranslation } from 'react-i18next';
import { Subpartner } from '@/modules/partner-program/model/types';
import PPCard from '../../core/pp-card/pp-card';
import SubpartnerStatsBtn from '../subpartner-stats-btn/subpartner-stats-btn';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import './subpartner-card.scss';

const b = bemCn('subpartner-card');

type SubpartnerCardProps = {
  subpartner: Subpartner;
}

const SubpartnerCard = ({ subpartner }: SubpartnerCardProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });

  const items = [
    {
      label: t('name', 'Имя'),
      value: subpartner.name,
    },
    {
      label: t('registration-date', 'Дата регистрации'),
      value: dayjsCustom(subpartner.created).format('DD.MM.YYYY HH:mm'),
    },
    {
      label: t('login', 'Логин'),
      value: subpartner.login,
    },
    {
      label: 'Email',
      value: subpartner.email,
    },
    {
      label: t('phone', 'Телефон'),
      value: subpartner.phone,
    },
  ];

  return (
    <PPCard
      items={items}
      contorls={
        <SubpartnerStatsBtn
          className={b('controls-btn')}
          subpartner={subpartner}
        />
      }
    />
  );
};

export default SubpartnerCard;
