import { useTranslation } from 'react-i18next';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import './manager-stats-page.scss';
import Mobile from '@/shared/components/responsive/mobile';
import { useAppSelector } from '@/shared/hooks';
import { getPrimaryWallet } from '@/modules/user';
import { useGetManagerTotalStatsQuery } from '@/modules/partner-program/api/managerApi';
import { skipToken } from '@reduxjs/toolkit/query';
import { PPTotalStat } from '@/modules/partner-program';
import PPTotalStatDesktop from '@/modules/partner-program/ui/pp-total-stat-desktop/pp-total-stat-desktop';
import Desktop from '@/shared/components/responsive/desktop';

const b = bemCn('manager-stats-page');

const ManagerStatsPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const wallet = useAppSelector(getPrimaryWallet);
  const { data } = useGetManagerTotalStatsQuery(wallet ? {
    currency: wallet.currency,
  } : skipToken);

  return (
    <div className={b()}>
      <div className={b('head')}>
        <h1 className={b('title')}>{t('your-stats', 'Ваша статистика')}</h1>
      </div>
      <Mobile>
        {data && <PPTotalStat stats={data} />}
      </Mobile>
      <Desktop>
        {data && <PPTotalStatDesktop stats={data} />}
      </Desktop>
    </div>
  );

};

export { ManagerStatsPage };
