import { bemCn } from '@shared/utils/helpers/bem-cn';
import { PropsWithCN } from '@shared/types/common';
import Spinner from '../spinner/spinner';
import { useAppSelector } from '@/shared/hooks';
import { getMainLogotoype } from '@/shared/store/app/selectors';
import './loader-big.scss';

const b = bemCn('loader-big');

const LoaderBig = ({ className }: PropsWithCN) => {
  const logotype = useAppSelector(getMainLogotoype);

  return (
    <div className={b(null, className)}>
      <Spinner className={b('spinner')} />
      <img className={b('logo')} src={logotype} alt="" />
    </div>
  );
};

export default LoaderBig;
