import { useEffect, useState } from 'react';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { useAppSelector } from '@shared/hooks';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs';
import Container from '@shared/components/container/container';
import { useScrollToTop } from '@shared/hooks/use-scrolltotop';
import { PageControlsMobile, LiveFilterMobile, SportEventsList } from '@/modules/line';
import {
  useGetBetapiSportsQuery,
  useGetBetapiEventsByTourneyQuery,
  DataType,
  getSports,
  getEventsListBySport,
} from '@/modules/line';
import { useTranslation } from 'react-i18next';
import './live-screen-mobile.scss';

const dataType: DataType = 'live';

const b = bemCn('live-screen-mobile');

const LiveScreenMobile = () => {
  const { i18n: { resolvedLanguage: dataLang } } = useTranslation();
  useScrollToTop();
  const [isRefetch, setIsRefetch] = useState(true);
  const [sportId, setActiveSportId] = useState('1');
  const params = { dataType, sportId, dataLang };

  const { isLoading: isSportsLoading } = useGetBetapiSportsQuery({ dataType, dataLang });
  const { isFetching: isEventsFetching } = useGetBetapiEventsByTourneyQuery(params, { pollingInterval: 4000 });

  const sportsList = useAppSelector(getSports({ dataType, dataLang }));
  const eventsList = useAppSelector(getEventsListBySport(params));

  useEffect(() => {
    if (!isEventsFetching) {
      setIsRefetch(false);
    }
  }, [isEventsFetching]);

  useEffect(() => {
    setIsRefetch(true);
  }, [sportId]);

  const isListLoading = isEventsFetching && isRefetch;

  return (
    <main className={b()}>
      <Container className={b('wrapper')}>
        <Breadcrumbs className={b('breadcrumbs')} />
        <PageControlsMobile className={b('controls')} />
        <LiveFilterMobile
          className={b('sport-filter')}
          items={sportsList}
          activeItem={sportId}
          onChange={(id) => setActiveSportId(id)}
          loading={isSportsLoading}
        />
        <SportEventsList className={b('list')}
          events={eventsList}
          loading={isListLoading}
        />
      </Container>
    </main>
  );
};

export default LiveScreenMobile;
