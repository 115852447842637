import { bemCn } from '@/shared/utils/helpers/bem-cn';
import './partner-stats-cards.scss';
import { ReactNode } from 'react';


type PartnerStatsCardsProps = {
  className?: string;
  items: {
    label: string;
    value: ReactNode;
  }[];
}

const PartnerStatsCards = ({ className, items }: PartnerStatsCardsProps) => {

  const b = bemCn('partner-stats-cards');

  return (
    <div className={b(null, className)}>
      {items && items.map((item) => (
        <div className={b('item')} key={item.label}>
          <div className={b('item-name')}>{item.label}</div>
          <div className={b('item-value')}>{item.value}</div>
        </div>
      ))}
    </div>
  );
};

export default PartnerStatsCards;
