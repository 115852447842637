import { useTranslation } from 'react-i18next';
import { useGetPartnerLinksQuery } from '@/modules/partner-program/api/partnerApi';
import PartnerLinksList from '@/modules/partner-program/ui/ref-link/partner-links-list/partner-links-list';
import ParnterLinksTable from '@/modules/partner-program/ui/ref-link/partner-links-table/partner-links-table';
import Desktop from '@/shared/components/responsive/desktop';
import Mobile from '@/shared/components/responsive/mobile';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import Pagination from '@/shared/components/pagination/pagination';
import { GetPartnerLinks } from '@/modules/partner-program/model/types';
import { useState } from 'react';
import './partner-links-page.scss';

const b = bemCn('partner-links-page');

const initParams: GetPartnerLinks = {
  page: 1,
  pageSize: 20,
};

const PartnerLinksPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const [params, setParams] = useState(initParams);
  const { data } = useGetPartnerLinksQuery(params);

  const handleChangeParams = (newParams: Partial<GetPartnerLinks>) => {
    setParams({ ...params, ...newParams });
  };

  const links = data?.items ?? [];
  const itemsCount = data?.count ?? 0;

  return (
    <div className={b()}>
      <div className={b('head')}>
        <h1 className={b('title')}>{t('your-links', 'Ваши ссылки')}</h1>
      </div>
      <Mobile>
        <PartnerLinksList links={links} />
      </Mobile>
      <Desktop>
        <ParnterLinksTable links={links} />
      </Desktop>
      <Pagination
        className={b('pagination')}
        page={params.page}
        pageSize={params.pageSize}
        totalItems={itemsCount}
        onChange={(page) => handleChangeParams({ page })}
      />
    </div>
  );
};

export { PartnerLinksPage };
