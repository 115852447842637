import { useTranslation } from 'react-i18next';
import { PartnerLink } from '../../../model/types';
import PPTable from '../../core/pp-table/pp-table';
import PartnerLinkStatsBtn from '../partner-link-stats-btn/partner-link-stats-btn';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import './partner-links-table.scss';

const b = bemCn('partner-promocodes-table');

type ParnterLinksTableProps = {
  links: PartnerLink[];
}

const ParnterLinksTable = ({ links }: ParnterLinksTableProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });

  return (
    <PPTable<PartnerLink>
      dataSource={links}
      className={b()}
    >
      <PPTable.Column<PartnerLink>
        dataIndex=""
        title=""
        render={(_, link) => (
          <PartnerLinkStatsBtn
            className={b('controls-btn')}
            link={link}
          />
        )}
      />
      <PPTable.Column<PartnerLink>
        dataIndex="projectName"
        title={t('project', 'Проект')}
      />
      <PPTable.Column<PartnerLink>
        dataIndex="link"
        title={t('link', 'Ссылка')}
      />
      <PPTable.Column<PartnerLink>
        dataIndex=""
        title={t('created', 'Создан')}
        render={(_, { created }) => dayjsCustom(created).format('DD:MM:YYYY')}
      />
    </PPTable>
  );
};

export default ParnterLinksTable;
