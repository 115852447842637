import { bemCn } from '@shared/utils/helpers/bem-cn';
import ButtonIcon from '@shared/components/button-icon/button-icon';
import { Link } from 'react-router-dom';
import EventCardTeam from '../components/event-card-team/event-card-team';
import { PropsWithCN } from '@shared/types/common';
import { useValueChanged } from '@shared/hooks/use-value-changed';
import IconSvg from '@/shared/components/icon-svg/icon-svg';
import { TEventBetItem, IEventItem } from '../../../model/line';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useDispatchedActions } from '@/shared/hooks';
import { getIsAuth } from '@/modules/auth';
import { useToggleFavoriteMatches } from '@/modules/user/hooks/use-toggle-favorite-mathes';
import './event-card-mobile.scss';

const b = bemCn('event-card-mobile');

type EventCardCountdownProps = {
  startDate: string;
}
const EventCardCountdown = ({ startDate }: EventCardCountdownProps) => {
  const { t } = useTranslation();
  const startMinutes = dayjsCustom(startDate).diff(dayjsCustom(), 'minutes') % 60;
  const startHours = dayjsCustom(startDate).diff(dayjsCustom(), 'hours') % 24;
  const startDays = dayjsCustom(startDate).diff(dayjsCustom(), 'days');

  return (
    <div className={b('countdown')}>
      <p className={b('countdown-title')}>
        {t('event.start-event', 'Начало через')}
      </p>
      <p className={b('countdown-value')}>{startDays} : {startHours} : {startMinutes}</p>
      <p className={b('countdown-label')}>
        <span className={b('countdown-label-item')}>
          {t('event.start-event-days', 'дн.')}
        </span>
        <span className={b('countdown-label-item')}>
          {t('event.start-event-hours', 'ч.')}
        </span>
        <span className={b('countdown-label-item')}>
          {t('event.start-event-minutes', 'мин.')}
        </span>
      </p>
    </div>
  );
};

const EventCardPinch = ({ className }: PropsWithCN) => (
  <IconSvg className={className}
    name='tennis'
    width={8}
    height={8}
  />
);

type EventCardPeriodProps = {
  periodTime: number;
  periodName: string;
}

const EventCardPeriod = ({ periodTime, periodName }: EventCardPeriodProps) => (
  <p className={b('period')}>
    <IconSvg className={b('period-icon')} name='clock' />
    {!!periodTime && (
      <span className={b('period-time')}>{periodTime}`</span>
    )}
    <span className={b('period-name')}>{periodName}</span>
  </p>
);

type EventCardTotalscoreProps = {
  scoreExtra: string;
  scoreTotal: string;
  pinchT1: boolean;
  pinchT2: boolean;
}

const EventCardTotalscore = ({
  scoreTotal,
  scoreExtra,
  pinchT1,
  pinchT2
}: EventCardTotalscoreProps) => (
  <div className={b('totla-score-wrapper')}>
    <div className={b('pinch-wrapper')}>
      <EventCardPinch
        className={b('pitch-icon', {
          t1: pinchT1,
          t2: pinchT2,
        })}
      />
      <p className={b('total-score')}>{scoreTotal}</p>
    </div>
    {scoreExtra && scoreExtra !== '0:0' && (
      <p className={b('extra-score')}>{scoreExtra}</p>
    )}
  </div>
);

const EventCardEmptyBets = () => (
  <>
    {['-', '-', '-'].map((item, i) => (
      <button className={b('bet', { bet: true })} key={i} disabled>
        <span className={b('bet-label')}>{item}</span>
      </button>
    ))}
  </>
);

type EventCardBetProps = {
  bet: TEventBetItem;
  onClick: (betId: string, e: React.MouseEvent) => void;
}

const EventCardBet = ({ bet, onClick }: EventCardBetProps) => {
  const [rate, diff] = useValueChanged(bet.rate);

  return (
    <button className={b('bet', { bet: true, diff })}
      type='button'
      onClick={(e) => onClick(bet.id, e)}
    >
      <span className={b('bet-label')}>{bet.name}</span>
      <span className={b('bet-value')}>{rate}</span>
    </button>
  );
};

export type EventCardMobileProps = {
  className?: string;
  event: IEventItem;
  onBetClick?: (betId: string) => void;
}

const EventCardMobile = (props: EventCardMobileProps) => {
  const { className, event, onBetClick } = props;
  const {
    id,
    startDate,
    team1,
    team2,
    periodName,
    scoreTotal,
    scorePeriod,
    isLive,
    timer,
    mainBets,
    dataType,
    pitch,
    scoreExtra,
    streamUrl,
    isFavorite,
  } = event;

  const { changeAuthModal } = useDispatchedActions();
  const isAuth = useAppSelector(getIsAuth);
  const date = dayjsCustom(startDate).format('DD.MM.YYYY');
  const dateTime = dayjsCustom(startDate).format('HH:mm');
  const { toggleFavorite, isLoading } = useToggleFavoriteMatches(event);

  const handleBetClick = (betId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (isAuth) {
      onBetClick?.(betId);
    } else {
      changeAuthModal('login');
    }
  };

  const handleFavoriteClick = (evt: React.MouseEvent) => {
    evt.preventDefault();
    toggleFavorite();
  };

  return (
    <div className={b(null, className)}>
      <Link className={b('wrapper')} to={`/${dataType}/${id}`}>
        <div className={b('head')}>
          <p className={b('date')}>
            {date}
            <span className={b('date-time')}>{dateTime}</span>
          </p>
          {isLive && (
            <EventCardPeriod
              periodTime={Math.floor(timer / 60)}
              periodName={periodName}
            />
          )}
          <ButtonIcon className={b('favorite', { active: isFavorite })}
            icon='heart'
            variant='secondary'
            onClick={handleFavoriteClick}
            disabled={isLoading}
          />
        </div>

        <div className={b('teams-wrapper')}>
          <EventCardTeam
            className={b('team')}
            logo={team1.icon}
            name={team1.name}
          />
          {isLive ? (
            <EventCardTotalscore
              scoreTotal={scoreTotal}
              scoreExtra={scoreExtra}
              pinchT1={pitch === team1.id}
              pinchT2={pitch === team2.id}
            />
          ) : (
            <EventCardCountdown startDate={startDate} />
          )}
          <EventCardTeam
            className={b('team')}
            logo={team2.icon}
            name={team2.name}
          />
        </div>
        {isLive && (
          <p className={b('periods-score')}>{scorePeriod}</p>
        )}

        <div className={b('bets')}>
          {mainBets.length <= 0 && <EventCardEmptyBets />}
          {mainBets.map((item) => (
            <EventCardBet
              key={item.id}
              bet={item}
              onClick={handleBetClick}
            />
          ))}
          {streamUrl && (
            <button className={b('bet', { stream: true })} >
              <IconSvg className={b('bet-icon')} name='live-tv' />
            </button>
          )}
        </div>
      </Link>
    </div>
  );
};

export default EventCardMobile;
