import { useTranslation } from 'react-i18next';
import { useGetSubPartnerPlayersQuery, useGetSubPartnerStatsQuery } from '@/modules/partner-program/api/managerApi';
import ButtonIcon from '@/shared/components/button-icon/button-icon';
import { useState } from 'react';
import PartnerStatsDrawer from '../../core/partner-stats-drawer/partner-stats-drawer';
import { Subpartner } from '@/modules/partner-program/model/types';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';


type SubpartnerStatsBtnProps = {
  className?: string;
  subpartner: Subpartner;
}

const SubpartnerStatsBtn = ({ className, subpartner, }: SubpartnerStatsBtnProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const [opened, setOpened] = useState(false);
  const partnerId = subpartner.id;

  const { data: subpartnerStats } = useGetSubPartnerStatsQuery({
    currency: 'RUB',
    partnerId,
  }, { skip: !opened });
  const { data: subpartnerPlayers } = useGetSubPartnerPlayersQuery({
    currency: 'RUB',
    partnerId,
  }, { skip: !opened });

  return (
    <>
      <ButtonIcon
        className={className}
        icon='stats'
        variant='bordered'
        onClick={() => setOpened(true)}
      />
      <PartnerStatsDrawer
        title={t('partner-stats', 'Статистика по партнёру')}
        isOpen={opened}
        onClose={() => setOpened(false)}
        metaInfo={
          <>
            <p>{t('partner-id', 'ID партнёра')}: {subpartner.id}</p>
            <p>{t('name', 'Имя')}: {subpartner.name}</p>
            <p>{t('login', 'Логин')}: {subpartner.login}</p>
            <p>Email: {subpartner.email}</p>
            <p>{t('phone', 'Телефон')}: {subpartner.phone}</p>
            <p>{t('registered', 'Зарегистрирован')}: {dayjsCustom(subpartner.created).format('DD.MM.YYYY')}</p>
          </>
        }
        stats={subpartnerStats}
        players={subpartnerPlayers?.items}
      />
    </>
  );
};

export default SubpartnerStatsBtn;
