import {
  useGetMascotGamesQuery,
  useGetPragmaticGamesQuery,
  useGetSmartsoftGamesQuery,
  useGetDigitainGamesQuery,
} from '@/modules/casino';
import { useGetAgtGamesQuery, useGetEndorphinaGamesQuery, useGetFivemenGamesQuery, useGetInbetGamesQuery, useGetPragmaticLobbyGamesQuery } from '../api/casino-api';
import { useGetStatusQuery } from '@/shared/store/app/app-api';
import { useCheckAuthQuery } from '@/modules/user';
import { getStoredCasinoGames } from '../utils/casino-localstorage';


export const useFetchCasinoGames = () => {
  const { data: status } = useGetStatusQuery();
  const { isSuccess: isAuthSuccess } = useCheckAuthQuery(undefined, { skip: !status?.system });

  const { isLoading: isMascotLoading } = useGetMascotGamesQuery(undefined, { skip: !status?.system || !status.mascot });
  const { isLoading: isSmartsoftLoading } = useGetSmartsoftGamesQuery(undefined, { skip: !status?.system || !status.smartsoft });
  const { isLoading: isPragmaticLoading } = useGetPragmaticGamesQuery(undefined, { skip: !status?.system || !status.pragmatic });
  // const { isLoading: isPragmaticAllLoading } = useGetPragmaticLobbyGamesQuery('all', { skip: !status?.system || !status.pragmatic });
  const { isLoading: isPragmaticHotLoading } = useGetPragmaticLobbyGamesQuery('hot', { skip: !status?.system || !status.pragmatic });
  const { isLoading: isPragmaticNewLoading } = useGetPragmaticLobbyGamesQuery('new', { skip: !status?.system || !status.pragmatic });
  const { isLoading: isInbetLoading } = useGetInbetGamesQuery(undefined, { skip: !status?.system || !status.inbet });
  const { isLoading: isDigitainLoading } = useGetDigitainGamesQuery(undefined, { skip: !status?.system || !status?.digitain });
  const { isLoading: isEndorphinaLoading } = useGetEndorphinaGamesQuery(undefined, { skip: !status?.system || !status.endorphina });
  const { isLoading: isAgtLoading } = useGetAgtGamesQuery(undefined, { skip: !status?.system || !status.agt || !isAuthSuccess });
  const { isLoading: isFivemenLoading } = useGetFivemenGamesQuery(undefined, { skip: !status?.system || !status.fivemen });

  const isMascotWaiting = isMascotLoading && !getStoredCasinoGames('mascot/games');
  const isSmartsoftWaiting = isSmartsoftLoading && !getStoredCasinoGames('smartsoft/games');
  const isPragmaticWaiting = isPragmaticLoading && !getStoredCasinoGames('pragmatic/games');
  const isPragmaticHotWaiting = isPragmaticHotLoading && !getStoredCasinoGames('pragmatic/lobby_games/hot');
  const isPragmaticNewWaiting = isPragmaticNewLoading && !getStoredCasinoGames('pragmatic/lobby_games/new');
  const isInbetWaiting = isInbetLoading && !getStoredCasinoGames('inbet/games');
  const isDigitainWaiting = isDigitainLoading && !getStoredCasinoGames('digitain/games');
  const isEndorphinaWaiting = isEndorphinaLoading && !getStoredCasinoGames('endorphina/games');
  const isAgtWaiting = isAgtLoading && !getStoredCasinoGames('agt/games');
  const isFivemenWaiting = isFivemenLoading && !getStoredCasinoGames('fivemen/games');


  return {
    isLoading: isMascotWaiting
      || isSmartsoftWaiting
      || isPragmaticWaiting
      || isPragmaticHotWaiting
      || isPragmaticNewWaiting
      || isInbetWaiting
      || isDigitainWaiting
      || isEndorphinaWaiting
      || isAgtWaiting
      || isFivemenWaiting
  };
};
