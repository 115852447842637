import { CommonFields } from '@/shared/types/common';
import { Subpartner, SubpartnerServer, PartnerLink, PartnerLinkServer, PartnerPromocode, PartnerPromocodeServer, PartnerStats, PartnerStatsServer, PlayerStats, PlayerStatsServer, PromocodeScript, PromocodeScriptServer } from '../model/types';

const adapteCommonStats = (stats: CommonFields<PartnerStatsServer, PlayerStatsServer>): CommonFields<PartnerStats, PlayerStats> => ({
  deposits: stats.deposits,
  adminDeposits: stats.admin_deposits,
  betsPlaced: stats.bets_placed,
  betsWinnings: stats.bets_winnings,
  betsLosses: stats.bets_losses,
  betsIngame: stats.bets_ingame,
  betsIncome: stats.bets_income,
  inbetPlaced: stats.inbet_placed,
  inbetWinnings: stats.inbet_winnings,
  inbetIncome: stats.inbet_income,
  vegasPlaced: stats.vegas_placed,
  vegasWinnings: stats.vegas_winnings,
  vegasIncome: stats.vegas_income,
  tvbetPlaced: stats.tvbet_placed,
  tvbetWinnings: stats.tvbet_winnings,
  tvbetIncome: stats.tvbet_income,
  pragmaticPlaced: stats.pragmatic_placed,
  pragmaticWinnings: stats.pragmatic_winnings,
  pragmaticIncome: stats.pragmatic_income,
  mascotPlaced: stats.mascot_placed,
  mascotWinnings: stats.mascot_winnings,
  mascotIncome: stats.mascot_income,
  betgamesPlaced: stats.betgames_placed,
  betgamesWinnings: stats.betgames_winnings,
  betgamesIncome: stats.betgames_income,
  smartsoftPlaced: stats.smartsoft_placed,
  smartsoftWinnings: stats.smartsoft_winnings,
  smartsoftIncome: stats.smartsoft_income,
  agtPlaced: stats.agt_placed,
  agtWinnings: stats.agt_winnings,
  agtIncome: stats.agt_income,
  income: stats.income,
});


export const adaptePartnerStats = (stats: PartnerStatsServer): PartnerStats => ({
  ...adapteCommonStats(stats),
  numClicks: stats.num_clicks,
  numRegistrations: stats.num_registrations,
  firstDeposits: stats.first_deposits,
  revshare: stats.revshare,
  currentIncome: stats.current_income,
});

export const adaptePlayerStats = (stats: PlayerStatsServer): PlayerStats => ({
  ...adapteCommonStats(stats),
  playerId: stats.player_id,
  playerName: stats.player_name,
});


export const adapteLink = (link: PartnerLinkServer): PartnerLink => ({
  refCode: link.ref_code,
  created: link.created,
  projectId: link.project_id,
  projectName: link.project_name,
  link: link.link,
  title: link.title,
});


export const adaptePromocodeScript = (script: PromocodeScriptServer): PromocodeScript => ({
  description: script.description,
  conditionType: script.condition_type,
  balanceType: script.balance_type,
  amountType: script.amount_type,
  isPrivate: script.is_private,
  currencyData: script.currency_data.map((item) => ({
    currency: item.currency,
    amount: item.amount,
    maxSum: item.max_sum,
  })),
  id: script.id,
});

export const adaptePromocode = (script: PartnerPromocodeServer): PartnerPromocode => ({
  id: script.id,
  created: script.created,
  name: script.name,
  scriptId: script.script_id,
  scriptDescription: script.script_description,
  userId: script.user_id,
});

export const adapteManagerPartner = (user: SubpartnerServer): Subpartner => ({
  id: user.id,
  login: user.login,
  email: user.email,
  phone: user.phone,
  phoneCountry: user.phone_country,
  name: user.name,
  role: user.role,
  created: user.created,
});
