import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { authSlice } from '@/modules/auth';
import { userSlice } from '@/modules/user';
import appSlice from '@shared/store/app/app-slice';

const rootActions = {
  ...appSlice.actions,
  ...authSlice.actions,
  ...userSlice.actions,
};

export const useAppDispatch = useDispatch<AppDispatch>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useDispatchedActions = () => {
  const dispatch = useAppDispatch();

  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
};
