import { useTranslation } from 'react-i18next';
import { useGetPartnerProjectsQuery } from '@/modules/partner-program/api/partnerApi';
import PartnerProjectsList from '@/modules/partner-program/ui/project/partner-projects-list/partner-projects-list';
import ParnterProjectsTable from '@/modules/partner-program/ui/project/partner-projects-table/partner-projects-table';
import Desktop from '@/shared/components/responsive/desktop';
import Mobile from '@/shared/components/responsive/mobile';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import CreateProjectBtn from '@/modules/partner-program/ui/project/create-project-btn/create-project-btn';
import './partner-projects-page.scss';
import { GetPratnerProjects } from '@/modules/partner-program/model/types';
import { useState } from 'react';
import Pagination from '@/shared/components/pagination/pagination';

const b = bemCn('partner-projects-page');

const initParams: GetPratnerProjects = {
  page: 1,
  pageSize: 20,
};

const PartnerProjectsPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const [params, setParams] = useState(initParams);
  const { data } = useGetPartnerProjectsQuery({});

  const handleChangeParams = (newParams: Partial<GetPratnerProjects>) => {
    setParams({ ...params, ...newParams });
  };

  const projects = data?.items ?? [];
  const itemsCount = data?.count ?? 0;

  return (
    <div className={b()}>
      <div className={b('head')} >
        <h1 className={b('title')}>{t('projects', 'Проекты')}</h1>
        <CreateProjectBtn leftIcon='plus'>
          {t('new-project', 'Новый проект')}
        </CreateProjectBtn>
      </div>
      <Mobile>
        <PartnerProjectsList projects={projects} />
      </Mobile>
      <Desktop>
        <ParnterProjectsTable
          projects={projects}
        />
      </Desktop>
      <Pagination
        className={b('pagination')}
        page={params.page}
        pageSize={params.pageSize}
        totalItems={itemsCount}
        onChange={(page) => handleChangeParams({ page })}
      />
    </div>
  );
};

export { PartnerProjectsPage };
