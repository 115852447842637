import RegisterForm, { baseSchema, RegisterFormData, RegisterFormError, RegisterFormProps } from './register-form';
import * as yup from 'yup';


type RegisterFormDataTelegram = RegisterFormData<{
  telegram: string;
  name: string;
}>

export type RegisterErrorTelegram = RegisterFormError<RegisterFormDataTelegram>

const schema: yup.ObjectSchema<RegisterFormDataTelegram> = baseSchema.shape({
  telegram: yup.string()
    .min(5, 'Недостаточно символов')
    .trim()
    .matches(/^[A-Za-z\d_]{5,32}$/, 'Не верный формат')
    .required('Введите никнейм'),
  name: yup.string()
    .required('Введите имя')
    .test(
      'is-two-words',
      'Поле должно состоять как минимум из двух слов',
      (value) => value.trim().split(/\s+/).length >= 2
    ),
});

const defaultValues: RegisterFormDataTelegram = {
  telegram: '',
  name: '',
  password: '',
  confirmPassword: '',
  currency: '',
  agree: false,
};

type RegisterFormByTelegramProps =
  Omit<RegisterFormProps<RegisterFormDataTelegram>, 'form'>

const RegisterFormByTelegram = (props: RegisterFormByTelegramProps) => (
  <RegisterForm
    {...props}
    form={{ defaultValues, schema }}
  >
    <RegisterForm.Telegram />
    <RegisterForm.Name />
  </RegisterForm>
);

export default RegisterFormByTelegram;
