import { IEventItem, TEventDetailed } from '@/modules/line/model/line';
import { useAddMatchToFavoriteMutation, useDeleteFavoriteMatchMutation } from '../store/user-api';
import { useAppSelector } from '@/shared/hooks';
import { getFavotieMatchId } from '../store/selectors';

type TUseToggleFavoriteMatches = (event: IEventItem | TEventDetailed) => {
  toggleFavorite: () => void;
  isLoading: boolean;
  isFavorite: boolean;
}

export const useToggleFavoriteMatches: TUseToggleFavoriteMatches = (event) => {
  const [addToFavorite, { isLoading: isAdding }] = useAddMatchToFavoriteMutation();
  const [deleteFromFavorite, { isLoading: isDeliting }] = useDeleteFavoriteMatchMutation();
  const favoriteId = useAppSelector((state) => getFavotieMatchId(state, event.mainId));

  const handleToggle = () => {
    if (favoriteId === undefined) {
      addToFavorite({
        matchId: Number(event.mainId),
        team1: event.team1.name,
        team2: event.team2.name,
        sportName: event.sport.name,
        sportId: Number(event.sport.id),
        tournamentName: event.tournament.name,
        tournamentId: Number(event.tournament.id),
        countryId: 0,
        countryName: '',
        dataType: event.dataType,
      });
    } else {
      deleteFromFavorite(favoriteId);
    }
  };


  return {
    toggleFavorite: handleToggle,
    isLoading: isAdding || isDeliting,
    isFavorite: !!favoriteId,
  };
};
