import {
  ManagerHomePage,
  ManagerStatsPage,
  ManagerUsersPage,
  PartnerHomePage,
  PartnerLinksPage,
  PartnerProjectsPage,
  PartnerPromocodesPage,
  PartnerStatsPage,
  PartnerUsersPage,
} from '@/pages/partner-program';

import { PartnerProgramLayout, PPNavigationItem } from '@/modules/partner-program';
import { Route } from 'react-router-dom';
import i18n from '@/shared/i18n/i18n';
import PrivateRoute from '@/widgets/private-route/private-route';


const partnerNav: PPNavigationItem[] = [
  {
    id: 'stats',
    name: i18n.t('partner-program.my-stats', 'Моя статистика'),
    link: '/partner-program/partner/stats'
  },
  {
    id: 'users',
    name: i18n.t('partner-program.my-users', 'Мои польозватели'),
    link: '/partner-program/partner/users'
  },
  {
    id: 'projects',
    name: i18n.t('partner-program.projects', 'Проекты'),
    link: '/partner-program/partner/projects'
  },
  {
    id: 'links',
    name: i18n.t('partner-program.links', 'Ссылки'),
    link: '/partner-program/partner/links'
  },
  {
    id: 'promocodes',
    name: i18n.t('partner-program.promocodes', 'Промокоды'),
    link: '/partner-program/partner/promocodes'
  },
];

const managerNav: PPNavigationItem[] = [
  {
    id: 'stats',
    name: i18n.t('partner-program.my-stats', 'Моя статистика'),
    link: '/partner-program/manager/stats'
  },
  {
    id: 'users',
    name: i18n.t('partner-program.my-partners', 'Мои партнёры'),
    link: '/partner-program/manager/partners'
  },
];

export const partnerProgramRoute = (
  <Route path="partner-program" element={<PrivateRoute />}>
    <Route path='manager' element={<PartnerProgramLayout navItems={managerNav} />}>
      <Route index element={<ManagerHomePage />} />
      <Route path='stats' element={<ManagerStatsPage />} />
      <Route path='partners' element={<ManagerUsersPage />} />
    </Route>

    <Route path='partner' element={<PartnerProgramLayout navItems={partnerNav} />}>
      <Route path='home' element={<PartnerHomePage />} />
      <Route path='stats' element={<PartnerStatsPage />} />
      <Route path='users' element={<PartnerUsersPage />} />
      <Route path='projects' element={<PartnerProjectsPage />} />
      <Route path='links' element={<PartnerLinksPage />} />
      <Route path='promocodes' element={<PartnerPromocodesPage />} />
    </Route>
  </Route>
);
