import { useTranslation } from 'react-i18next';
import { PartnerLink } from '@/modules/partner-program/model/types';
import ButtonIcon, { IButtonIconProps } from '@/shared/components/button-icon/button-icon';
import PartnerStatsDrawer from '../../core/partner-stats-drawer/partner-stats-drawer';
import { useState } from 'react';
import { useGetPartnerLinkPlayersQuery, useGetPartnerLinkStatsQuery } from '@/modules/partner-program/api/partnerApi';
import { dayjsCustom } from '@/widgets/settings/utils/datetime-format';


type PartnerLinkStatsBtnProps = IButtonIconProps & {
  link: PartnerLink;
}

const PartnerLinkStatsBtn = ({ link, className }: PartnerLinkStatsBtnProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const [opened, setOpened] = useState(false);

  const { data: linkStatsData } = useGetPartnerLinkStatsQuery({
    currency: 'RUB',
    refCode: link.refCode,
  }, { skip: !opened });

  const { data: linkPlayers } = useGetPartnerLinkPlayersQuery({
    currency: 'RUB',
    refCode: link.refCode,
  }, { skip: !opened });

  return (
    <>
      <ButtonIcon className={className}
        icon='stats'
        variant='bordered'
        onClick={() => setOpened(true)}
      />
      <PartnerStatsDrawer
        title='Статистика по промокоду'
        isOpen={opened}
        onClose={() => setOpened(false)}
        metaInfo={
          <>
            <p>{t('project-title', 'Название проекта')}: {link.projectName}</p>
            <p>{t('created', 'Создан')}: {dayjsCustom(link.created).format('DD.MM.YYYY')}</p>
          </>
        }
        stats={linkStatsData}
        players={linkPlayers?.items}
      />
    </>
  );
};

export default PartnerLinkStatsBtn;
