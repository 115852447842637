import { useTranslation } from 'react-i18next';
import {
  PartnerPromocodesList,
  PartnerPromocodesTable,
} from '@/modules/partner-program';
import Desktop from '@/shared/components/responsive/desktop';
import Mobile from '@/shared/components/responsive/mobile';
import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { useState } from 'react';
import { useGetPartnerPromocodesQuery, } from '@/modules/partner-program/api/partnerApi';
import { GetPartnerPromocodes } from '@/modules/partner-program/model/types';
import Loader from '@/shared/components/loader/loader';

import './partner-promocodes-page.scss';
import CreatePartnerPromocodeBtn from '@/modules/partner-program/ui/promocode/create-partner-promocode-btn/create-partner-promocode-btn';
import Pagination from '@/shared/components/pagination/pagination';

const b = bemCn('partner-promocodes-page');

const initParams: GetPartnerPromocodes = {
  page: 1,
  pageSize: 20,
};

const PartnerPromocodesPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const [params, setParams] = useState<GetPartnerPromocodes>(initParams);

  const {
    data: dataPromocodes,
    isLoading: isPromocodesLoading
  } = useGetPartnerPromocodesQuery(params);

  const handleChangeParams = (newParams: Partial<GetPartnerPromocodes>) => {
    setParams({ ...params, ...newParams });
  };


  const promocodes = dataPromocodes?.items ?? [];
  const itemsCount = dataPromocodes?.count ?? 0;

  return (
    <div className={b()}>
      <div className={b('head')}>
        <h1 className={b('title')}>{t('promocodes', 'Промокоды')}</h1>
        <CreatePartnerPromocodeBtn className={b('new-btn')} leftIcon='plus'>
          {t('new-promocode', 'Новый промокод')}
        </CreatePartnerPromocodeBtn>
      </div>
      {isPromocodesLoading && <Loader />}
      <Mobile>
        <PartnerPromocodesList
          className={b('list')}
          promocodes={promocodes}
        />
      </Mobile>
      <Desktop>
        <PartnerPromocodesTable
          className={b('list')}
          promocodes={promocodes}
        />
      </Desktop>
      <Pagination
        className={b('pagination')}
        page={params.page}
        pageSize={params.pageSize}
        totalItems={itemsCount}
        onChange={(page) => handleChangeParams({ page })}
      />
    </div>
  );
};

export { PartnerPromocodesPage };
