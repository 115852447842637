import { bemCn } from '@shared/utils/helpers/bem-cn';
import { NavLink } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import AviatrixLogo from '@assets/provider-logos/aviatrix-logo-flat.svg?react';
import JetxLogo from '@assets/provider-logos/jetx-logo.svg?react';
// import aviatrixLogoImg from '@assets/provider-logos/aviatrix-logo-flat.svg';
// import jetxLogoImg from '@assets/provider-logos/jetx-logo.svg';
import { Trans, useTranslation } from 'react-i18next';
import './site-nav.scss';

const menuItems = [
  {
    id: 'home',
    title: 'Главная',
    link: '/',
  },
  {
    id: 'live',
    title: 'Live',
    link: '/live',
  },
  {
    id: 'line',
    title: 'Линия',
    link: '/line',
  },
  {
    id: 'casino',
    title: 'Казино',
    link: '/games',
  },
  // {
  //   id: 'results',
  //   title: 'Результаты',
  //   link: '/results',
  // },
  // {
  //   id: 'streams',
  //   title: 'Трансляции',
  //   link: '/streams',
  // },
];

type SiteNavProps = {
  className?: string;
}

const b = bemCn('site-nav');

const SiteNav = ({ className }: SiteNavProps) => {
  const [activeItem, setActiveItem] = useState(menuItems[0].id);
  const listRef = useRef<HTMLUListElement>(null);
  const activeItemRef = useRef<HTMLLIElement>(null);
  const { t: mainNavT } = useTranslation('', { keyPrefix: 'main-nav' });

  useEffect(() => {
    if (listRef.current && activeItemRef.current) {
      const listRect = listRef.current.getBoundingClientRect();
      const activeItemRect = activeItemRef.current.getBoundingClientRect();

      if (activeItemRect.right > listRect.right) {
        activeItemRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'end',
        });
      }

      if (activeItemRect.left < listRect.left) {
        activeItemRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    }
  }, [activeItem]);

  return (
    <nav className={b(null, className)}>
      <ul className={b('list', 'no-scrollbar')} ref={listRef}>
        {menuItems.map((item) => (
          <li className={b('item')}
            key={item.id}
            ref={item.id === activeItem ? activeItemRef : null}
          >
            <NavLink
              className={({ isActive }) => b('link', { active: isActive })}
              to={item.link}
              onClick={() => setActiveItem(item.id)}
              end
            >
              <Trans i18nKey={item.id} t={mainNavT}>
                {item.title}
              </Trans>
            </NavLink>
          </li>
        ))}

        <li className={b('item')}
          ref={'jetx' === activeItem ? activeItemRef : null}
        >
          <NavLink
            className={({ isActive }) => b('link', { active: isActive })}
            to="/games/smartsoft/JetX/JetX"
            onClick={() => setActiveItem('jetx')}
          >
            <span className="visually-hidden">JatX</span>
            <JetxLogo className={b('item-img', { type: 'jetx' })}
              width={35}
              height={15}
            />
            {/* <img className={b('item-img')} src={jetxLogoImg} alt="" /> */}
          </NavLink>
        </li>
        <li className={b('item')}
          ref={'aviatrix' === activeItem ? activeItemRef : null}
        >
          <NavLink
            className={({ isActive }) => b('link', { active: isActive })}
            to="/games/aviatrix"
            onClick={() => setActiveItem('aviatrix')}
          >
            <span className="visually-hidden">Aviatrix</span>
            <AviatrixLogo className={b('item-img', { type: 'aviatrix' })}
              width={59}
              height={15}
            />
            {/* <img className={b('item-img')} src={aviatrixLogoImg} alt="" /> */}
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default SiteNav;
