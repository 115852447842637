export const agreementList = [
  {
    id: 'terms-of-use',
    title: 'Условия использования',
    content: `
      <h2>Условия использования</h2>
      <p>Настоящие положения и условия, а также правила, применимые ко всем играм на сайте BOOMPARI и любым другим документам, упомянутым в них, являются соглашением и пониманием между сторонами и регулируют договорные отношения между компанией BOOMPARI и владельцем учетной записи сервиса («Клиент»).</p>
      <p>Пожалуйста, ознакомьтесь детально с данным соглашением перед его принятием. Если вы не согласны с содержанием, пожалуйста, воздержитесь от использования сервиса. При использовании услуг BOOMPARI вы автоматически даёте согласие на нижеследующие условия, положения:</p>
      <ol>
        <li>Клиент добровольно вступает в соглашение с Компанией, принимает изложенные ниже условия. Настоящие положения и условия применяются независимо от того, осуществляется ли доступ к сервису через телефон, настольный браузер или мобильный браузер.</li>
        <li>Вы автоматически даёте согласие на перечень остальных условий, правил, которые опубликованы на игровом сайте Компании. Данные положения и условия настоящей версии действуют до даты, указанной как ___ года. Они остаются в силе до тех пор, пока не будет опубликована новая версия на веб-сайте и принята владельцем учетной записи в соответствии с настоящими условиями.</li>
        <li>Клиент подтверждает факт достижения им минимального возраста, с момента наступления которого может беспрепятственно пользоваться игровыми услугами, согласно законодательству страны вашего проживания.</li>
        <li>Клиент не пребывает на сегодняшний момент в странах, попадающих под ограничения.</li>
        <li>Возраст Клиента составляет 18 и более лет.</li>
        <li>Клиент играет на сайте добровольно, исходя из собственных интересов; использует для ставок свои собственные средства.</li>
        <li>Регистрация и создания аккаунта на игровом сайте является единственным.</li>
        <li>Клиент по собственному желанию не производил самоисключение с игровых сайтов на протяжении предыдущих 12 месяцев.</li>
        <li>Клиент внимательно прочитал, изучил и полностью соглашается со всеми приведёнными условиями правилами.</li>
        <li>Клиент добровольно подтверждает: предоставленная им персональная информация верна. Вы осознаёте, что она может быть в любой момент проверена. Клиент знает, что предоставление ложных или искажённых данных приведет к автоматической аннуляции выигрышей.</li>
        <li>Клиент осведомлен и признаете: BOOMPARI устанавливает определённые лимиты на максимальную сумму выигрыша, доступной для получения и вывода за день/неделю/месяц.</li>
        <li>Мы ожидаем от пользователя проявления ответственности в период игры. Решение о пользовании услугами BOOMPARI полностью зависит от вас. Клиент принимает решение о ведении игры исключительно по своему выбору, осознавая вероятные риски. Принимая решение использовать сервисы BOOMPARI, вы даёте автоматическое подтверждение об осознании возможности утраты вложенных финансовых средств в ходе  игры, несёте самостоятельную ответственность за любые потери. Также Клиент подтверждает отсутствие у него любых претензий к BOOMPARI/партнерам Компании, директорам, сотрудникам в отношении результатов игрового процесса или проигрышей.</li>
        <li>Посещая разделы, вкладки нашего сайта/создавая аккаунт для игры, Клиент дает согласие с предоставленным перечнем пунктов соглашения, промо, политикой конфиденциальности, нюансами совершения ставок, спецпредложениями, акциями, политикой конфиденциальности, предлагаемыми на официальном сайте BOOMPARI.
        </li>
      </ol>
      <p>Ещё раз перечитайте, изучите пункты, представленные выше, перед тем, как согласится с данным соглашением, принимая его условия. При несогласии с выше представленными пунктами, Клиенту следует прекратить использование нашего игрового сайта. Любое последующее использование сайта BOOMPARI автоматически рассматривается как согласие Клиента с пунктами Соглашения.</p>
    `,
  },
  {
    id: 'risk-notification',
    title: 'Уведомление о рисках',
    content: `
      <h2>Уведомление о рисках</h2>
      <p>Играя на сайте BOOMPARI, вы осознаете, что существует определенный риск потери денежных средств, которые были внесены на ваш счет. Кроме того, стоит отметить, что в некоторых странах онлайн-азартные игры могут быть незаконными.</p>
      <p>Мы хотели бы проинформировать вас о том, что администрация сайта не оказывает юридическую поддержку и не может гарантировать законность использования услуг нашего веб-сайта. Кроме того, дружественная компания BOOMPARI не может гарантировать Клиенту, что услуги нашего веб-сайта соответствуют требованиям законодательства вашей страны.</p>
      <p>Решение о правомерности использования наших услуг администрация сайта оставляет полностью на ваше усмотрение и предполагает наличие личной ответственности перед законом вашей юрисдикции. Посещение нашего веб-сайта и участие в играх происходит исключительно на ваш риск.</p>
      <p>Отметим, что веб-сайты и игры BOOMPARI доступны без каких-либо явных или подразумеваемых гарантий.</p>
    `,
  },
  {
    id: 'terms-of-refund',
    title: 'Условия возврата финансовых средств',
    content: `
      <h2>Условия возврата финансовых средств</h2>
      <p>Возврат денежных средств после их использования в процессе игры не предусмотрен. Запрос на возврат будет рассмотрен только в случае, если он подается в течение первых 24 часов после предполагаемой транзакции или в течение 30 дней, если пользователь утверждает, что к его учетной записи имел доступ другой человек (или несовершеннолетний).</p>
      <p>Мы, компания BOOMPARI, оставляем за собой право отказать в возврате или обратной транзакции до тех пор, пока не будет установлена личность пользователя в соответствии с применимыми законами вашей юрисдикции.</p>
      <p>Вы соглашаетесь предоставить нотариально удостоверенную идентификацию или другие документы, подтверждающие вашу личность по нашему требованию. В случае, если такая идентификация не будет предоставлена в течение 30 дней с момента запроса, возврат или обратная транзакция не будут выполнены. В подобном случае ваша игровая учетная запись закрывается администрацией, после чего вы безвозвратно теряете средства, которые были на балансе игрока.</p>
      <p>Принятое администрацией BOOMPARI решение будет окончательным, обязательным и обжалованию не подлежащим. Клиент обязуется честно играть в игры из меню и не использовать никакие способы, влияющие на результат игры, включая компьютеры, математические уравнения, системы ставок и прочее.</p>
    `,
  },
  {
    id: 'terms-of-bet-canceling',
    title: 'Условия отмены',
    content: `
      <h2>Условия отмены</h2>
      <p>Подтверждение ставки онлайн Клиентом в ходе игрового процесса онлайн на BOOMPARI считается окончательным и не может быть внезапно изменено или отменено. Возможно сделать ставку на вероятность исхода, противоположную стартовому прогнозу. В некоторых случаях Это поможет снизить возможные потери, однако подобная ставка также не может быть отменена.</p>
      <p>Все выплаты по ставкам на BOOMPARI рассчитываются, согласно коэффициентам, действующим на момент размещения ставки. Любые последующие изменения коэффициентов не влияют на уже размещенные ставки. Во избежание ошибок, недоразумений, настоятельно рекомендуем Клиенту тщательно проверять все совершённые им ставки в купонах перед их подтверждением онлайн.</p>
      `,
  },
  {
    id: 'kyc-and-aml',
    title: 'KYC & AML',
    content: `
      <h2>KYC & AML</h2>
      <p>Компания BOOMPARI прилагает все усилия по борьбе с отмыванием денег и международным терроризмом (AML-политика). Мы строго придерживаемся принципов, которые предотвращают незаконную деятельность и соблюдаем соответствующее законодательство, о чём уведомляем наших Клиентов заранее.</p>
      <p>При регистрации на сайте BOOMPARI Клиент автоматически соглашается с политикой AML и дальнейшим использованием сайта подтверждает свое согласие. При необходимости проверки финансовых средств, используемых для игры, Клиент соглашается предоставить все необходимые данные. Если деятельность клиента или операции на его счетах будут считаться подозрительными, аккаунт будет заблокирован.</p>
      <p>В случае подозрений в отношении честного происхождения ваших средств и их возможной связи с легализацией незаконно полученных доходов или финансированием терроризма, мы обязаны сообщить об этом официальным органам. В соответствии с правилами AML-политики, мы заблокируем такие средства и предпримем другие соответствующие меры.</p>
      <p>Отмывание денег подразумевает скрытие информации о происхождении, источнике, местонахождении, правах собственности и других правах на незаконно полученное имущество, а также его конвертацию, перемещение и использование с целью сокрытия незаконного происхождения или содействия преступным действиям.</p>
      <p>В целях предотвращения проникновения криминально-инфицированного капитала и распространения преступности, компания BOOMPARI активно сотрудничает с государственными и международными организациями по борьбе с отмыванием денег и финансированием терроризма.</p>
      <p>При регистрации на сайте BOOMPARI в качестве Клиента, вы обязуетесь соблюдать применимые законы и нормативные акты в области противодействия отмыванию средств и финансированию терроризма. Клиент также подтверждает, что у него нет информации или подозрений о незаконном происхождении средств, использованных для пополнения счета.</p>
      <p>Клиент соглашается добровольно предоставить компании BOOMPARI необходимую информацию для соблюдения действующего законодательства. Мы проводим проверки личности пользователей в соответствии с рисками; собираем и безопасно храним их данные, документы и отчеты.</p>
      <p>Мы также проверяем личные данные на совпадение с списками лиц, подозреваемых в терроризме. Компания BOOMPARI проведет проверку личных данных пользователей с целью обнаружения совпадений со списком лиц, подозреваемых в террористической деятельности, сформированным государственными и независимыми органами.</p>
      <p>Для проведения такой проверки необходимы следующие идентификационные данные:</p>
      <ul>
        <li>полное имя пользователя, дата рождения (для физических лиц);</li>
        <li>адрес проживания или адрес регистрации пользователя;</li>
        <li>данные об источнике происхождения средств, которые планируется депонировать на счете на веб-сайте компании BOOMPARI.</li>
      </ul>
      <p>Для подтверждения достоверности предоставленных данных компания BOOMPARI может запросить следующие документы:</p>
      <ul>
        <li>паспорт, идентификационную карту или другой документ, заменяющий их, который содержит полное имя пользователя, дату рождения и фотографию владельца документа, выданный национальными государственными органами;</li>
        <li>документ-подтверждение фактического адреса проживания (счет за коммунальные платежи, полученный не ранее, чем за 3 месяца от текущей проверки).</li>
      </ul>
      <p>Компания BOOMPARI оставляет за собой право запросить иные документы, необходимые для идентификации личности клиента.</p>
      <p>Пожалуйста, предоставьте нам минимальные данные для подтверждения вашей личности. Мы будем признательны за сотрудничество.</p>
    `,
  },
];
