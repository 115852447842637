import { bemCn } from '@shared/utils/helpers/bem-cn';

import Container from '@shared/components/container/container';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs';
import { useParams } from 'react-router-dom';

import { useAppSelector } from '@shared/hooks';
import {
  useTournamentEventsToggler,
  useGetBetapiEventsByTourneyQuery,
  getTournamentsEvents,
  type DataType
} from '@/modules/line';
import { PageControlsMobile, TournamentEventsList } from '@/modules/line';
import { useTranslation } from 'react-i18next';
import { useScrollToTop } from '@/shared/hooks/use-scrolltotop';
import './line-events-screen-mobile.scss';

const b = bemCn('line-events-screen-mobile');

const dataType: DataType = 'line';

const LineEventsScreenMobile = () => {
  useScrollToTop();
  const { i18n: { resolvedLanguage: dataLang } } = useTranslation();
  const { closedItems, handleToggleItem } = useTournamentEventsToggler();
  const { tournamentId = '' } = useParams();
  const params = { tournamentId, dataType, dataLang };
  const { isFetching: isEventsFetching } =
    useGetBetapiEventsByTourneyQuery(params, { skip: !tournamentId });

  const eventsList = useAppSelector(getTournamentsEvents(params));

  return (
    <div className={b()}>
      <Container className={b('wrapper')}>
        <Breadcrumbs className={b('breadcrumbs')} />
        <PageControlsMobile className={b('controlls')} />
        <TournamentEventsList
          events={eventsList}
          closedItems={closedItems}
          onToggle={handleToggleItem}
          loading={isEventsFetching}
        />
      </Container>
    </div>
  );
};

export default LineEventsScreenMobile;
