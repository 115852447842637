import { bemCn } from '@/shared/utils/helpers/bem-cn';
import { PartnerPromocode } from '@/modules/partner-program/model/types';
import PartnerPromocodeCard from '../partner-promocode-card/partner-promocode-card';
import './partner-promocodes-list.scss';

const b = bemCn('partner-promocodes-list');

type PartnerPromocodesListProps = {
  className?: string;
  promocodes: PartnerPromocode[];
}

const PartnerPromocodesList = ({
  className,
  promocodes,
}: PartnerPromocodesListProps) => (
  <div className={b(null, className)}>
    {promocodes.map((promocode) => (
      <PartnerPromocodeCard
        key={promocode.id}
        promocode={promocode}
      />
    ))}
  </div>
);

export default PartnerPromocodesList;
