import { bemCn } from '@shared/utils/helpers/bem-cn';
import SearchFilter from '@/shared/components/search-filter/search-filter';
import { PropsWithCN } from '@shared/types/common';
import ButtonIcon from '@shared/components/button-icon/button-icon';
import './page-controls-mobile.scss';
import { useThemeContext } from '@/modules/theme/model/theme-context';


type PageControlsMobileProps = PropsWithCN & {
  onSearch?: (value: string) => void;
  onFavoriteClick?: () => void;

}

const PageControlsMobile = ({
  className,
  onSearch,
  onFavoriteClick,
}: PageControlsMobileProps) => {
  const b = bemCn('page-controls-mobile');
  const { isDark } = useThemeContext();

  return (
    <div className={b(null, className)}>
      <div className={b('wrapper')}>
        <SearchFilter className={b('search')}
          onChange={onSearch}
          inverted={isDark}
        />
        <ButtonIcon className={b('btn')}
          icon="calendar"
          variant='secondary'
        />
        <ButtonIcon className={b('btn')}
          icon="clock"
          variant='secondary'
        />
        <ButtonIcon className={b('btn')}
          icon="heart"
          variant='secondary'
          onClick={onFavoriteClick}
        />
      </div>
    </div>
  );
};

export default PageControlsMobile;
