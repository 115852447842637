import { ChangeEventHandler, FocusEventHandler, useState } from 'react';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import withController from '../../hocs/with-controller';
import IconSvg from '../icon-svg/icon-svg';
import { useToggle } from 'usehooks-ts';
import './text-field.scss';

const b = bemCn('text-field');

export type TextFieldProps = {
  label?: string;
  type?:
  | 'email'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'text'
  | 'url'
  | 'string';
  placeholder?: string;
  name?: string;
  id?: string;
  value?: string | number;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  className?: string;
  classNameInput?: string;
  status?: 'error' | 'success';
  error?: string;
  disabled?: boolean;
  inverted?: boolean;
  clearable?: boolean;
  onClear?: () => void;
}

const TextField = (props: TextFieldProps) => {
  const {
    label,
    type = 'text',
    placeholder,
    value,
    onChange,
    className,
    classNameInput,
    name,
    id,
    status,
    error,
    disabled,
    onBlur,
    inverted,
    clearable,
    onClear,
  } = props;
  const [currentValue, setCurrentValue] = useState(value);
  const [showPassword, toggleShowPassword] = useToggle(false);
  const isPassword = type === 'password';
  const isError = status === 'error' && error;

  return (
    <div className={b({ inverted, status, disabled }, className)}>
      {label && (
        <label htmlFor={id} className={b('label')}>{label}</label>
      )}
      <div className={b('input-wrapper')}>
        <input className={b('input', { type }, classNameInput)}
          type={type === 'password' && showPassword ? 'text' : type}
          id={id}
          name={name}
          value={value ?? currentValue ?? ''}
          placeholder={placeholder}
          onChange={(e) => {
            onChange?.(e);
            setCurrentValue(e.target.value);
          }}
          onBlur={onBlur}
          disabled={disabled}
        />
        {isPassword && (
          <button className={b('show-btn')} type='button' onClick={toggleShowPassword}>
            <IconSvg className={b('show-btn-icon')}
              name={showPassword ? 'hide' : 'show'}
            />
          </button>
        )}
        {clearable && (
          <button className={b('clear-btn')} type='button' onClick={onClear}>
            <IconSvg className={b('clear-btn-icon')} name='close' />
          </button>
        )}
      </div>
      {isError && <span className={b('error')}>{error}</span>}
    </div>
  );
};

export const TextFieldControlled = withController(TextField);

export default TextField;
