import { bemCn } from '@shared/utils/helpers/bem-cn';
import Flag from '@shared/components/flag/falg';
import GradientIcon, { GRADIENT_COLORS } from '@shared/components/gradient-icon/gradient-icon';
import IconSvg from '@/shared/components/icon-svg/icon-svg';
import Loader from '@/shared/components/loader/loader';
import { FC, useEffect, useState } from 'react';
import type { TEventsFilter } from '../../model/types';
import type { ITournamentItem } from '../../model/line';
import FilterContext, { useFilterContext } from '../../model/filterContext';
import { IEventsFilterCountry, IEventsFilterSport } from '../../model/types';
import { Trans } from 'react-i18next';
import type { ColorName as GradientColor } from '@shared/components/gradient-icon/gradient-icon';
import Button from '@/shared/components/button/button';
import './events-filter-desktop.scss';

const b = bemCn('events-filter-desktop');

type TournamentBtnProps = {
  tournament: ITournamentItem;
  active: boolean;
}

const TournamentBtn: FC<TournamentBtnProps> = ({ tournament, active }) => {
  const { onFilteChange } = useFilterContext();
  return (
    <button className={b('tournaments-btn', { active })}
      type='button'
      onClick={() => onFilteChange({
        tournament: active ? '0' : tournament.id
      })}
    >
      {tournament.name}
    </button>
  );
};


type CountryBtnProps = {
  country: IEventsFilterCountry;
  active: boolean;
}

const CountryBtn: FC<CountryBtnProps> = ({ country, active }) => {
  const { onFilteChange } = useFilterContext();
  return (
    <button className={b('countries-btn', { active })}
      type='button'
      onClick={() => onFilteChange({
        country: active ? '0' : country.id,
        tournament: '0'
      })}
    >
      <Flag className={b('countries-icon')} countryId={country.id} />
      <span className={b('countries-name')}>{country.name}</span>
      <IconSvg className={b('countries-toggle-icon')} name="chevron-down" />
    </button>
  );
};

type CountryitemProps = {
  country: IEventsFilterCountry;
}

const Countryitem: FC<CountryitemProps> = ({ country }) => {
  const { filter } = useFilterContext();
  const isActive = country.id === filter.country;
  return (
    <div>
      <CountryBtn country={country} active={isActive} />
      {isActive && (
        <div className={b('tournaments-list')}>
          {country.tournaments.map((tournament) => (
            <TournamentBtn
              key={tournament.id}
              tournament={tournament}
              active={tournament.id === filter.tournament}
            />
          ))}
        </div>
      )}
    </div>
  );
};

type SportBtnProps = {
  sport: IEventsFilterSport;
  active: boolean;
  color?: GradientColor;
  onClick?: () => void;
  isOpened?: boolean;
  showToggler?: boolean;
}

const SportBtn: FC<SportBtnProps> = ({ sport, active, color = 'purple', onClick, isOpened, showToggler }) => {
  const { onFilteChange } = useFilterContext();
  return (
    <button className={b('sports-btn', { active, opened: isOpened })}
      type="button"
      onClick={() => {
        onFilteChange({
          sport: sport.id,
          tournament: '0',
          country: '0'
        });
        onClick?.();
      }}
    >
      <GradientIcon className={b('sports-icon')}
        iconName={sport.icon}
        color={color}
      />
      <span className={b('sports-name')}>{sport.name}</span>
      <span className={b('sports-counter')}>{sport.counter}</span>
      {showToggler && (
        <IconSvg className={b('sports-toggle-icon')} name="chevron-down" />
      )}
    </button>
  );
};

type SportItemProps = {
  sport: IEventsFilterSport;
  index: number;
  isLiveEvents?: boolean;
}

const SportItem: FC<SportItemProps> = ({ sport, index, isLiveEvents }) => {
  const [isOpened, setOpened] = useState(false);
  const { filter } = useFilterContext();
  const isActive = sport.id === filter.sport;

  useEffect(() => {
    if (sport.id !== filter.sport) {
      setOpened(false);
    }
  }, [filter.sport, sport.id]);

  return (
    <div>
      <SportBtn
        sport={sport}
        active={isActive}
        color={GRADIENT_COLORS[index % GRADIENT_COLORS.length]}
        onClick={() => setOpened(!isOpened)}
        isOpened={isOpened}
        showToggler={!isLiveEvents}
      />
      {!isLiveEvents && isOpened && (
        <div className={b('countries-list')}>
          {sport.countries.map((country) => (
            <Countryitem key={country.id} country={country} />
          ))}
        </div>
      )}
    </div>
  );
};

type EventsFilterDesktopProps = {
  className?: string;
  items: IEventsFilterSport[];
  loading?: boolean;
  filter: TEventsFilter;
  onFilteChange: (newFilter: Partial<TEventsFilter>) => void;
  isLiveEvents?: boolean;
}

const EventsFilterDesktop: FC<EventsFilterDesktopProps> = ({
  className,
  items,
  loading,
  filter,
  onFilteChange,
  isLiveEvents,
}) => {
  const [shownCount, setShownCount] = useState(20);

  const handleShowMoreClick = () => {
    const nextCount = shownCount + 20;
    setShownCount(Math.min(nextCount, items.length));
  };

  return (
    <FilterContext.Provider value={{ filter, onFilteChange }}>
      <div className={b(null, className)}>
        {loading && <Loader className={b('loader')} />}
        {!loading && items.slice(0, shownCount).map((sport, index) => (
          <SportItem
            key={sport.id}
            sport={sport}
            index={index}
            isLiveEvents={isLiveEvents}
          />
        ))}
        {!loading && shownCount < items.length && (
          <Button className={b('show-more')} variant="text" onClick={handleShowMoreClick}>
            <Trans i18nKey="common.show-more">
              Показать ещё
            </Trans>
          </Button>
        )}
      </div>
    </FilterContext.Provider>
  );
};

export default EventsFilterDesktop;
